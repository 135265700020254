@import './../../../../../../styles/_variables';

.AdditionalQuestions {
  .accordion {
    text-align: center;

    .additional-questions-toggle {
      background: transparent;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;

      &:focus {
        outline: none !important;
        border: none; }

      img {
        transform: scaleY(1);
        transition: transform 0.2s ease-in-out; }

      &.active {
        img {
          transform: scaleY(-1); } } } } }
