.toast {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #333;
  z-index: 9;
  border-radius: $border-radius;
  background: rgb(216, 216, 216);
  @include box-shadow(0.25rem 0.25rem 0.25rem 0.05rem rgba(0, 0, 0, 0.5));

  .toast-header {
    justify-items: flex-end;

    button.close {
      font-size: 1rem; } } }
