@import './../../../../styles/_variables';

.NextButton {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  opacity: 0;
  transition: none;

  @media (min-width: map_get($grid-breakpoints, 'sm')) {
    align-items: stretch; }

  &.show {
    opacity: 1;
    transition: opacity 0.2s ease-in-out; }

  .help {
    display: flex;
    justify-content: flex-end;
    letter-spacing: 0.25px;
    margin-top: 0.25rem;

    .enter-icon {
      height: 0.65rem;
      margin: 0 0.35rem;
      align-self: flex-end; } } }
