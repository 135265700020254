@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.Result {
  .explanation {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    > * {
      max-width: 80%;

      @media (min-width: map_get($grid-breakpoints, 'sm')) {
        max-width: 100%; } } }

  .diagnosis {
    h1 {
      font-size: 1.28rem; }

    .criteria {
      font-size: 1.85rem;
      font-weight: bold;
      line-height: 1.2; }

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
      h1 {
        font-weight: bold;
        font-size: 1.85rem; }

      .criteria {
        font-size: 1rem;
        font-weight: normal; } } }

  .buttons {
    flex-wrap: wrap;

    .btn {
      width: 100%;
      margin-bottom: 1rem;
      text-transform: uppercase;
      @include box-shadow(none);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2rem;

      img {
        max-height: 1.5rem; } }

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
      flex-wrap: initial;

      .btn {
        width: auto;
        border-radius: 0.5rem;

        img {
          max-height: initial; } } } } }
