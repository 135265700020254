.btn {
  padding: 0.65rem 1rem;
  border-radius: 0.5rem;
  @include box-shadow(0.25rem 0.25rem 0.25rem 0.05rem rgba(0, 0, 0, 0.5));

  &.btn-primary {
    background: $white;
    border-color: $white;
    color: $primary;

    &:active,
    &:focus {
      background: $white;
      border-color: $white;
      color: $primary; }

    &:active {
      @include box-shadow(none); }

    &:hover {
      background: darken($white, 10%);
      border-color: darken($white, 10%); } }

  &.btn-default {
    @include box-shadow(none);
    background: transparent;
    border-color: white;
    color: white;
    border-width: 2px;

    &:hover {
      background: rgba($white, 0.05); } }

  &.btn-unstyled {
    @include box-shadow(none);
    background: transparent;
    border: none;
    padding: 0; }

  &.btn-link {
    @include box-shadow(none);
    font-size: 0.85rem;
    background: transparent;
    border: none;
    text-decoration: underline;
    color: $white;
    padding: 0;

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
      font-size: 1rem; } } }

