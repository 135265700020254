@import './../../../../styles/_variables';

.ReadMore {
  hr {
    margin-left: 15px;
    margin-right: 15px;

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
      margin: 0; } }

  p {
    font-size: 0.8rem; }

  a.btn-default {
    font-weight: bold;
    font-size: 0.85rem;

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
      font-size: 0.7rem; } } }
