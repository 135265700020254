@import './../../../../../styles/_variables';

.HomeButton {
  cursor: pointer;

  img {
    width: 2.3rem;

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
      width: 100%; } } }
