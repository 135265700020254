@import './../../../styles/variables';

.Login {
  h2 {
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 4rem;

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
      font-size: 1.2rem; } }

  .nav {
    border-bottom: 2px solid $white;
    display: flex;

    .nav-item {
      flex-grow: 1;
      flex-basis: 50%;

      @media (min-width: map_get($grid-breakpoints, 'sm')) {
        flex-basis: initial; }

      a {
        color: $white;
        text-transform: uppercase;
        font-size: 0.85rem;
        display: flex;
        align-items: center;
        padding: 0.5rem 0.5rem;
        font-weight: 100;

        @media (min-width: map_get($grid-breakpoints, 'sm')) {
          font-size: 1.4rem;
          padding: 0.5rem 1rem; }

        &.active {
          background: rgba($white, 0.15);
          font-weight: normal; }

        &:hover {
          background: rgba($white, 0.05);
          &.active {
            background: rgba($white, 0.15); } }

        img {
          height: 2rem; } } } }

  .form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid $white;
    border-radius: 0;
    padding: 1.5rem 0;
    font-size: 1.3rem;
    color: $white; }

  small {
    font-size: 0.85rem;

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
      font-size: 80%; } }


  button.btn[type="submit"] {
    padding: 0.65rem 1.5rem; } }
