@import './../../../../../../styles/_variables';

.ProgressBarSections {
  .progress {
    border-radius: 1rem;
    height: 1.2rem;
    background-color: rgba($white, 0.5);

    .progress-bar {
      background-color: transparent;
      color: white;
      font-size: 0.7rem;
      text-transform: uppercase;

      &.active {
        border-radius: 0 1rem 1rem 0;
        background-color: $white;
        color: $purple; }

      &.completed {
        background-color: $white;
        color: rgba($purple, 0.5); } } } }
