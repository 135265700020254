@import './../../../../../styles/_variables';

.Title {
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 0.85rem;
    margin-bottom: 0;

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
      font-size: 1.3em; } } }

