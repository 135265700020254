@import './../../../../../../../styles/_variables';

.Choice,
.Summary {

  .choice-1-of-2 {
    $color: rgba($white, 0.6);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-2-of-2 {
    $color: $white;
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-1-of-3 {
    $color: rgba($white, 0.6);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-2-of-3 {
    $color: rgba($white, 0.8);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-3-of-3 {
    $color: $white;
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-1-of-4 {
    $color: rgba($white, 0.6);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-2-of-4 {
    $color: rgba($white, 0.733);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-3-of-4 {
    $color: rgba($white, 0.867);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-4-of-4 {
    $color: $white;
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-1-of-5 {
    $color: rgba($white, 0.6);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-2-of-5 {
    $color: rgba($white, 0.7);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-3-of-5 {
    $color: rgba($white, 0.8);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-4-of-5 {
    $color: rgba($white, 0.9);
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } }

  .choice-5-of-5 {
    $color: $white;
    background: $color;
    border-color: $color;

    &:hover {
      background: darken($color, 10%); } } }
